.btn,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  position: relative;
  display: inline-block;
  padding: 18px 30px;
  font-size: 11px;
  font-family: inherit;
  line-height: 1.5;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  color: $bg-color;
  background-color: $button-dark;
  text-align: center;
  border: 0;
  border-radius: 0;
  transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  outline: 0;

  &::after {
    display: none;
  }

  &:hover,
  :focus,
  :active {
    color: $bg-color;
    background-color: lighten($button-dark, 14%);
    outline: 0;
  }
}

.btn + .btn {
  margin-top: 2em;

  @include breakpoint(350px) {
    margin-top: 0;
    margin-left: 2em;
  }
}

button:disabled {
  cursor: not-allowed;
  opacity: .65;
  transition: background-color .2s ease;

  &:hover,
  :focus {
    background-color: $button-dark;
  }
}
